import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng-lts/button';
import { CheckboxModule } from 'primeng-lts/checkbox';
import { DialogModule } from 'primeng-lts/dialog';
import { DropdownModule } from 'primeng-lts/dropdown';
import { InputSwitchModule } from 'primeng-lts/inputswitch';
import { InputTextModule } from 'primeng-lts/inputtext';
import { InputTextareaModule } from 'primeng-lts/inputtextarea';
import { MenuModule } from 'primeng-lts/menu';
import { MultiSelectModule } from 'primeng-lts/multiselect';
import { ProgressSpinnerModule } from 'primeng-lts/progressspinner';
import { RadioButtonModule } from 'primeng-lts/radiobutton';
import { SidebarModule } from 'primeng-lts/sidebar';
import { SlideMenuModule } from 'primeng-lts/slidemenu';
import { TabViewModule } from 'primeng-lts/tabview';
import { ToastModule } from 'primeng-lts/toast';
import { ToolbarModule } from 'primeng-lts/toolbar';
import { AldCommonModule } from '@al/design-patterns/common';
import * as manifest from './manifest';


@NgModule({
    declarations: [
        ...manifest.MODULE_COMPONENTS,
        ...manifest.MODULE_DIRECTIVES,
        ...manifest.MODULE_PIPES,
    ],
    imports: [
        AldCommonModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        CheckboxModule,
        DialogModule,
        InputTextModule,
        InputTextareaModule,
        MenuModule,
        SidebarModule,
        ToastModule,
        ToolbarModule,
        ProgressSpinnerModule,
        InputSwitchModule,
        MultiSelectModule,
        DropdownModule,
        SlideMenuModule,
        TabViewModule,
        RadioButtonModule,
        OverlayModule,
        PortalModule
    ],
    exports: [
        ...manifest.MODULE_COMPONENTS,
        ...manifest.MODULE_DIRECTIVES,
        ...manifest.MODULE_PIPES,
    ],
    providers: [
        ...manifest.MODULE_SERVICES,
        ...manifest.MODULE_PIPES,
        DatePipe
    ]
})
export class NgGenericComponentsModule {
}
