/**
 * AlManageExperienceService
 *
 * @author Bryan Tabarez <bryan.tabarez@alertlogic.com>
 *
 * @copyright Alert Logic, Inc 2019
 */

import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import {
    AlActingAccountResolvedEvent, 
    AlGlobalizer, 
    AlLocation, 
    AlLocatorService, 
    AlSession, 
    AlEntitlementCollection
} from '@al/core';
import { AlToastService } from '@al/ng-generic-components';
import { IEWarningState } from '../types/navigation.types';
import { AlExperiencePreferencesService } from './al-experience-preferences.service';
import { AlNavigationService } from './al-navigation.service';
import { DOCUMENT } from '@angular/common';


@Injectable({
    providedIn: 'root'
})
export class AlManageExperienceService {

    private firstTime = true;

    private readonly toastIEWarning = {
        sticky: true,
        closable: false,
        data: {
            html: "<p>Your current browser may prevent you from accessing certain features on this page. " +
                "Alert Logic recommends using the latest version of a supported browser for optimal performance. " +
                "Go to <a href='https://docs.alertlogic.com/requirements/operating-system-browsers.htm' target='_blank'> " +
                "Operating System and Browser Requirements</a> to see supported operating systems and browsers.</p>",
            buttons: [
                {
                    key: 'ie-warning',
                    label: 'OK',
                    class: 'p-col-fixed',
                    textAlign: 'center'
                }
            ]
        }
    };

    private readonly mdrEntitlements = 'assess|detect|respond|tmpro|lmpro|fortra_xdr';
    private renderer: Renderer2;

    constructor(
        public experiencePreferences: AlExperiencePreferencesService,
        public alNavigation: AlNavigationService,
        public alToastService: AlToastService,
        rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    init = (): void => {
        AlSession.notifyStream.attach(AlActingAccountResolvedEvent, this.onActingAccountResolved);
        this.loadNavigationExperience();
        this.alToastService.getButtonEmitter('ie-toast').subscribe(() => this.handleIEToastButtonEmitter());
        this.expose();
        this.experiencePreferences.getTheme().then(theme => this.setTheme(theme));
        this.firstTime = false;
    }

    /**
     * Load the navigation experience - set experience, schema, toast messages
     */
    async loadNavigationExperience(): Promise<void> {
        const effectiveEntitlements: AlEntitlementCollection = await AlSession.getEffectiveEntitlements();
        let mdrNavEntitlements = this.mdrEntitlements;
        // Adding the MDR experience for Standalone WAF customers, this is a temporal entitlement.
        // IMPORTANT! TODO: After GA of Standalone WAF change it by 'managed_waf'
        mdrNavEntitlements += '|managed_waf';
        const isAaidDashboardsEnabled: boolean = effectiveEntitlements.evaluateExpression(mdrNavEntitlements);

        if (isAaidDashboardsEnabled) {
            this.setDashboardExp();
        } else {
            this.setClassicExp();
            this.setIe11Toast();
        }
    }

    /**
     * Save and load the experience preference selected through ToastComponent
     */
    manageExperienceOption(option: string): void {
        switch (option) {
            case 'go-to-classic':
                this.setClassicExp();
                if (AlLocatorService.getActingNode().locTypeId === AlLocation.DashboardsUI) {
                    this.alNavigation.navigate.byNgRoute( [ '/legacy-dashboards' ] );
                }
                break;
            case 'go-to-dashboards':
                this.setDashboardExp();
                break;
            case 'ie-warning':
                this.experiencePreferences.saveIEWarningPreferences("showed");
                break;
            default:
                console.warn(`manageExperienceOption: ${option} is an invalid experience option`);
                break;
        }
    }

    private async setIe11Toast(): Promise<void> {
        if (this.alNavigation.getExperience() === "beta") {
            const state: IEWarningState = await this.experiencePreferences.getIEWarningPreferences();
            if (this.alNavigation.isIEBrowser() && (!state || state === "not_showed")) {
                this.experiencePreferences.saveIEWarningPreferences("showing");
                setTimeout(() =>
                    this.alToastService.showMessage('ie-toast', this.toastIEWarning)
                );
            }
        }
    }

    private setDashboardExp(): void {
        this.alNavigation.setExperience('beta');
        this.alNavigation.setSchema('mdr2');
    }

    private setClassicExp(): void {
        this.alNavigation.setExperience("default");
        this.alNavigation.setSchema("cie-plus3");
    }

    private onActingAccountResolved = (): void => {
        // The first time it will be called from the init method when the view is ready
        if ( ! this.firstTime ) {
            this.loadNavigationExperience();
        }
        this.firstTime = false;
    }

    private handleIEToastButtonEmitter = (): void => {
        this.experiencePreferences.saveIEWarningPreferences("showed");
        this.alToastService.clearMessages('ie-toast');
    }

    private expose(): void {
        AlGlobalizer.expose('al.experienceManager', {
            routingHost: this,
            setExperience: (option: string) => {
                this.manageExperienceOption(option);
            },
            setTheme: (theme: string) => {
                this.setTheme(theme);
            }
        });
    }

    private setTheme(theme: string): void {
        this.removeThemes();
        switch(theme) {
            case "fortra":
                this.renderer.addClass(this.document.body, "al-fortra-light-theme");
                this.setFavicon('fortra-favicon.ico');
                break;
            case "fortra-dark":
                this.renderer.addClass(this.document.body, "al-fortra-dark-theme");
                this.setFavicon('fortra-favicon.ico');
                break;
            case "darkMode":
                this.renderer.addClass(this.document.body, 'darkMode');
                this.renderer.addClass(this.document.querySelector('html'), 'darkMode');
                break;
            case "blueMode":
                this.renderer.addClass(this.document.body, 'blueMode');
                this.renderer.addClass(this.document.querySelector('html'), 'blueMode');
                break;
            case "alertlogic":
            default:
                this.renderer.addClass(document.querySelector('html'), "currentConsole");
                this.renderer.addClass(document.body, "al-light-theme");
                this.setFavicon('favicon.ico');
                break;
        }
        this.experiencePreferences.saveTheme(theme);
    }

    private removeThemes(): void {
        this.renderer.removeClass(this.document.body, "al-light-theme");
        this.renderer.removeClass(this.document.body, "al-fortra-light-theme");
        this.renderer.removeClass(this.document.body, "al-fortra-dark-theme");
        this.renderer.removeClass(this.document.body, 'darkMode');
        this.renderer.removeClass(this.document.body,  'blueMode');
        this.renderer.removeClass(this.document.querySelector('html'), 'darkMode');
        this.renderer.removeClass(this.document.querySelector('html'), 'blueMode');
    }

    setFavicon(iconUrl: string): void {
        const link: HTMLLinkElement = this.document.querySelector("link[rel~='icon']") as HTMLLinkElement;
        if (link) {
            this.renderer.setAttribute(link, 'href', iconUrl);
        }
    }
}
