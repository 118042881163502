/**
 * Error Translation Service
 *
 * Provides logic to analyze various types of error and convert them into useful (but properly redacted!!!) human readable form.
 *
 * @author Maryit Sanchez <msanchez@alertlogic.com>
 * @author M-M-Massive McNielsen <knielsen@alertlogic.com>
 *
 * @copyright Alert Logic, Inc 2020
 */

import { AxiosResponse } from 'axios';
import { Injectable } from '@angular/core';
import {
    AlDefaultClient, 
    AlDataValidationError, 
    AlWrappedError, 
    AlErrorHandler, 
    APIRequestParams, 
    getJsonPath
} from '@al/core';
import { AlSCErrorDictionary, AlSCErrorDescriptor } from '../types/al-generic.types';
import { AlExternalContentManagerService } from './al-external-content-manager.service';


export interface AlErrorDescriptor {
    title:string;
    description:string;
    details?:any;
}

@Injectable( { 
    providedIn: 'root' 
} ) 
export class AlErrorTranslatorService {

    constructor( public contentManager:AlExternalContentManagerService ) {
    }

    public async describeError( error:any ):Promise<AlErrorDescriptor> {

        let title = "Something is wrong";
        let description = await this.getErrorDescription( error );
        let details:any;

        if ( AlDefaultClient.isResponse( error ) ) {
            //  This error is an HTTP response descriptor, indicating an API error has occurred -- format appropriately
            title = "Unexpected API Response";
            details = this.redact( this.compactErrorResponse( error ) );
        } else if ( error instanceof AlDataValidationError ) {
            //  This error is a data validation error, indicating we retrieved data but it wasn't the data we expected
            title = "Unexpected API Response Structure";
            details = this.redact( this.compactDataValidationError( error ) );
        } else if ( error instanceof AlWrappedError ) {
            //  This error is an outer error with a reference to an inner exception.
            details = this.redact( this.compactWrappedError( error ) );
        } else if ( error instanceof Error ) {
            //  Generic Error object
            details = this.redact( this.compactError( error ) );
        }

        return { title, description, details };
    }

    /**
     *  Utility function to descend into arbitrarily nested and potentially circular data, replacing any AIMS tokens
     *  or Authorization headers with a redaction marker.
     *
     *  If `trimCircularity` is true (default), circular references will be flattened with a special string, making the object
     *  suitable for serialization.
     */
    public redact( info:any, trimCircularity:boolean = true, circular:any[] = [] ):any {
        if ( typeof( info ) === 'object' && info !== null ) {
            if ( circular.includes( info ) ) {
                if ( trimCircularity ) {
                    return "(circular)";
                } 
            } else {
                circular.push( info );
                Object.keys( info ).forEach( key => {
                    if ( /x-aims-auth-token/i.test( key ) || /authorization/i.test( key ) ) {
                        info[key] = 'XXXXX'; //  REDACTED
                    } else {
                        info[key] = this.redact( info[key], trimCircularity, circular );
                    }
                } );
            }
        } else if ( typeof( info ) === 'string' ) {
            return info.replace( /X-AIMS-Auth-Token['"\s:]*([a-zA-Z0-9+\=\/]+)['"]/gi, 
                                 ( completeMatch:string, token:string ) => completeMatch.replace( token, 'XXXXX' ) )
                       .replace( /Authorization['"\s:]*([a-zA-Z0-9+\=\/\s]+)['"]/gi,
                                 ( completeMatch:string, token:string ) => completeMatch.replace( token, 'XXXXX' ) )
        }
        return info;
    }

    protected async getErrorDescription( error:any ):Promise<string> {
        if ( typeof( error ) === 'string' ) {
            return error;
        } else if ( AlDefaultClient.isResponse( error ) ) {
            return await this.getResponseDescription( error );
        } else if ( error instanceof AlDataValidationError ) {
            return error.message;
        } else if ( error instanceof AlWrappedError ) {
            return await this.consolidateWrappedErrorDescription( error );
        } else if ( error instanceof Error ) {
            return error.message;
        } else {
            return "An unknown error prevented this view from rendering.  If this persists, please contact Alert Logic support for assistance.";
        }
    }

    protected compactDataValidationError( error:AlDataValidationError ):any {
        return this.compactError( error, "Data Validation Error", {
            validationSchemaId: error.schemaId,
            errors: error.validationErrors || [],
            dataOrigin: error.request ? `${error.request.method} [${error.request.url}]` : 'Not Available'
        } );
    }

    protected compactErrorResponse( response:AxiosResponse<any> ):any {
        return {
            data: response.data,
            status: response.status,
            statusText: response.statusText,
            headers: response.headers,
            config: response.config
        };
    }

    protected compactWrappedError( error:AlWrappedError ):any {
        let cursor = error;
        const stack = [];
        while( cursor ) {
            if ( AlDefaultClient.isResponse( cursor ) ) {
                stack.push( this.compactErrorResponse( cursor ) );
            } else if ( cursor instanceof AlDataValidationError ) {
                stack.push( this.compactDataValidationError( cursor ) );
            } else if ( cursor instanceof Error ) {
                stack.push( this.compactError( cursor ) );
            } else if ( typeof( cursor ) === 'string' ) {
                stack.push( cursor );
            } else {
                stack.push( "Eggplant Parmesiano with Spider Eggs" );
            }
            if ( cursor instanceof AlWrappedError ) {
                cursor = cursor.getInnerError();
            } else {
                cursor = null;
            }
            cursor = cursor instanceof AlWrappedError ? cursor.getInnerError() : null;
        }
        return stack;
    }

    protected compactError( error:Error, type:string = "Error", otherProperties?:any ):any {
        const compact:any = {
            type,
            message: error.message,
            stack: error.stack ? error.stack.split( "\n" ).map( line => line.trim() ) : null
        };
        if ( otherProperties ) {
            Object.assign( compact, otherProperties );
        }
        return compact;
    }

    protected async consolidateWrappedErrorDescription( error:AlWrappedError|Error|AxiosResponse|string ) {
        let description = '';
        let cursor = error;
        let adjustCapitalization = ( text:string ) => {
            if ( ! text || text.length === 0 ) {
                return '';
            }
            if ( description.length > 0 ) {
                let firstChar = text[0];
                if ( firstChar === firstChar.toUpperCase() ) {
                    return firstChar.toLowerCase() + text.substring( 1 );
                }
            }
            return text;
        };
        while( cursor ) {
            if ( description.length > 0 ) {
                description += `: `;
            }
            if ( cursor instanceof Error ) {
                description += adjustCapitalization( cursor.message );
            } else if ( AlDefaultClient.isResponse( cursor ) ) {
                description += adjustCapitalization( await this.getResponseDescription( cursor ) );
            } else if ( typeof( cursor ) === 'string' ) {
                description += adjustCapitalization( cursor );
            }
            cursor = cursor instanceof AlWrappedError ? cursor.getInnerError() : null;
        }
        return description;
    }

    /**
     * Matches a response TODO(kjn): hook this up to the content service, when it's available, and use content from there instead of here :)
     */
    protected async getResponseDescription( response:AxiosResponse<any> ) {
        const request = response.config as APIRequestParams;
        const serviceName = 'service_name' in request ? request.service_name : "a required service";
        const status = response.status;
        const statusText = response.statusText;
        try {
            const criteria:any = {
                serviceName,
                status,
                statusText,
                response
            };
            const dictionary = await this.contentManager.getJsonResource<AlSCErrorDictionary>( "presentation:errors/api.json" );
            if ( serviceName in dictionary.services ) {
                const message = this.lookupResponseDescription( dictionary.services[serviceName], criteria );
                if ( message ) {
                    return this.interpolateMessageData( message, criteria );
                }
            }
            if ( 'default' in dictionary.services ) {
                const message = this.lookupResponseDescription( dictionary.services.default, criteria );
                if ( message ) {
                    return this.interpolateMessageData( message, criteria );
                }
            }
        } catch( e ) {
            AlErrorHandler.log( e, "Failed to retrieve global API error dictionary; falling back to hardcoded response descriptors." );
        }
        switch( status ) {
            case 400 :
                return `${serviceName} doesn't appear to understand one of our requests.  If this condition persists, please contact Alert Logic support.`;
            case 401 :
                return `${serviceName} doesn't appear to be accepting our identity or authentication state.  If this condition persists after reauthenticating, please contact Alert Logic support.`;
            case 403 :
                return `${serviceName} is denying our authorization to access its data.  If this condition persists after reauthenticating, please contact Alert Logic support.`;
            case 404 :
                return "The data you are trying to access doesn't appear to exist.  If you are certain this is an error and the condition persists, please contact Alert Logic support.";
            case 410 :
                return "The data you're trying to access doesn't appear to exist anymore.  If you are certain this is an error and the condition persists, please contact Alert Logic support.";
            case 418 :
                return "Sadly, the data you're looking for has turned into a teapot.  Tragic but delicious!";
            case 500 :
                return `${serviceName} has experienced an unexpected internal error.  If this condition persists, please contact Alert Logic support.`;
            case 502 :
                return `${serviceName} has failed because of an unexpected response from an upstream service.  If this condition persists, please contact Alert Logic support.`;
            case 503 :
                return `${serviceName} is currently unavailable.  If this condition persists, please contact Alert Logic support.`;
            case 504 :
                return `${serviceName} is not responding quickly enough.  If this condition persists, please contact Alert Logic support.`;
            default :
                return `${serviceName} responded in an unexpected way (${status}/${statusText}).  If this condition persists, please contact Alert Logic support.`;
        }
    }

    /**
     *  Finds an appropriate message in a list of error definitions
     */
    protected lookupResponseDescription( definitions:AlSCErrorDescriptor[], criteria:any ):string|null {
        const match = definitions.find( definition => {
            let mismatches:number = 0;
            Object.keys( definition.when ).forEach( conditionKey => {
                const conditionalValue = definition.when[conditionKey];
                const actualValue = getJsonPath( criteria, conditionKey, null );
                if ( Array.isArray( conditionalValue ) ) {
                    mismatches += ( conditionalValue as any[] ).includes( actualValue ) ? 0 : 1;
                } else {
                    mismatches += ( actualValue === conditionalValue ) ? 0 : 1;
                }
            } );
            return mismatches === 0;
        } );
        return match ? match.message : null;
    }

    /**
     * Interpolates request information into an error message
     */
    protected interpolateMessageData( message:string, criteria:any ):string {
        return message.replace( /\{\{([a-zA-Z0-9_.]+)\}\}/g, ( match, identifier ) => {
            return getJsonPath( criteria, identifier );
        } );
    }
}
