/**
 * View Helper Component - supports load indicators, zero states, error states, and view-level notifications.
 *
 * @author Maryit Sanchez <msanchez@alertlogic.com>
 * @author M-M-Massive McNielsen <knielsen@alertlogic.com>
 *
 * @copyright Alert Logic, Inc 2020
 */

import { AxiosResponse } from 'axios';
import {
    Component, 
    EventEmitter, 
    Input, 
    OnChanges, 
    Output, 
    SimpleChanges, 
    ViewChild
} from '@angular/core';
import {
    AlDefaultClient, 
    AlDataValidationError, 
    AlWrappedError, 
    AlErrorHandler, 
    APIRequestParams, 
    getJsonPath
} from '@al/core';
import { AldToastService, ToastRef } from '@al/design-patterns';
import { AlNotificationPanelComponent } from '../al-notification-panel/al-notification-panel.component';
import { AlErrorTranslatorService } from '../services/al-error-translator.service';
import { AlNotification, AlNotificationType } from '../types/al-notification-panel.types';


@Component({
    selector: 'al-view-helper',
    templateUrl: './al-view-helper.component.html',
    styleUrls: ['./al-view-helper.component.scss']
})
export class AlViewHelperComponent implements OnChanges {

    protected static emittedWarning = false;

    /**
     * Indicates whether the loading animation should be displayed.
     */
    @Input() loading: boolean       =   false;

    /**
     * Indicates whether the empty zero state should be displayed.
     */
    @Input() empty: boolean         =   false;

    /**
     * Zero state flavor, that has different background,color or is wrapped in a card
     * default whiteBg shadowCard
     */
    @Input() zeroStateFlavor        = 'default';

    /**
     * Determines whether the view helper presents as a overlay or as an inline component.
     */
    @Input() inline: boolean        =   false;

    /**
     * Determines whether or not to show a blur overlay while the view is loading.
     */
    @Input() blur:boolean           =   false;

    /**
     * Indicates whether or not the error state should be displayed and, if so, if they should be
     * presented as blocking or non-blocking errors.  Different types of error objects will generate
     * various types of additional details (e.g., `Error` instances will show a stack trace when
     * "Show Details" is expanded, response descriptors will show the HTTP error and request details,
     * etc.)
     *
     * Main views should prefer to use ViewChild() to call the view helper's `setError()` method
     * directly.
     */
    @Input() error?:string|Error|AxiosResponse;
    @Input() blocking?:boolean;

    /**
     * Indicates whether the retry mechanism should be allowed after the helper enters blocking error mode; this will either trigger the
     * `retry` @Output or invoke retryHandler if it is present.
     */
    @Input() allowRetry:boolean = false;

    /**
     * Setting this to a valid callback will cause the retry button to be exposed when a blocking error is displayed.  Clicking the button
     * will clear the blocking error and invoke the callback.
     */
    @Input() retryHandler?:() => void|boolean; 

    /**
     * If retryHandler === true, this event emitter will be used to communicate that the user has clicked
     * the try again button.
     * */
    @Output() retry                 =   new EventEmitter<void>();

    @Output() notifications         =   new EventEmitter<AlNotification>();

    @Input() notifyPanel?:boolean;          /* @deprecated */

    @ViewChild(AlNotificationPanelComponent) notificationsPanel: AlNotificationPanelComponent;

    public errorTitle:string = "Something is wrong";
    public errorDescription?:string;
    public showError?:boolean;
    public showErrorDetails?:boolean;
    public errorDetails?:string;
    public applyBlur:boolean = false;

    constructor( public toaster:AldToastService,
                 public errorTxr:AlErrorTranslatorService ) {
    }

    ngOnChanges( changes:SimpleChanges ) {
        if ( 'error' in changes ) {
            this.setError( changes.error.currentValue, this.blocking );
        }
        if ( 'notifyPanel' in changes && ! AlViewHelperComponent.emittedWarning ) {
            AlErrorHandler.log( `Notice: it is no longer necessary to provide a 'notifyPanel' input to al-view-helper` );
            AlViewHelperComponent.emittedWarning = true;
        }
        if ( 'retryHandler' in changes && typeof( changes.retryHandler ) === 'function' ) {
            this.allowRetry = true;
        }

        this.refreshState();
    }

    /**
     *  Turns the empty/zero-state mode on.
     */
    public enableZeroState() {
        this.empty = true;
        this.refreshState();
    }

    /**
     *  Turns the loading indicator on.
     */
    public startLoading() {
        this.loading = true;
        this.refreshState();
    }

    /**
     *  Turns the loading indicator off.
     */
    public stopLoading() {
        this.loading = false;
        this.refreshState();
    }

    /**
     *  ---- Classic Notification Panel Messaging -----------------------------------
     */

    /**
     *  Emits a notification
     */
    public notify(text: string, type: AlNotificationType, autoDismiss: number = 0) {
        this.notifications.emit(new AlNotification(text, type, autoDismiss));
    }

    /**
     *  Emits an info-style notification.
     */
    public notifyInfo(text: string, autoDismiss: number = 0, flush: boolean = false) {
        this.notifications.emit(AlNotification.info(text, autoDismiss, flush));
    }

    /**
     *  Emits a warning notification.
     */
    public notifyWarning(text: string, autoDismiss: number = 0, flush: boolean = false) {
        this.notifications.emit(AlNotification.warning(text, autoDismiss, flush));
    }

    /**
     *  Emits an error notification.
     */
    public notifyError(text: string, autoDismiss: number = 0, flush: boolean = false) {
        this.notifications.emit(AlNotification.error(text, autoDismiss, flush));
    }

    /**
     *  Emits an success notification.
     */
    public notifySuccess(text: string, autoDismiss: number = 0, flush: boolean = false) {
        this.notifications.emit(AlNotification.success(text, autoDismiss, flush));
    }

    /**
     *  Flush the notification panel
     */
    public cleanNotifications() {
        if (this.notificationsPanel) {
            this.notificationsPanel.flush();
        }
    }

    /**
     * Emits an info-style toast message.
     */
    public toastInfo<T=any>( message:string, autoDismiss:number = 7500 ):ToastRef {
        return this.toaster.notifyInfo( message, autoDismiss );
    }

    /**
     * Emits a warning-styled toast message.
     */
    public toastWarning<T=any>( message:string, autoDismiss:number = 7500 ):ToastRef {
        return this.toaster.notifyWarning( message, autoDismiss );
    }

    /**
     * Emits an error toast with the given message and autodismiss.
     */
    public toastError<T=any>( message:string, autoDismiss:number = 7500 ):ToastRef {
        return this.toaster.notifyError( message, autoDismiss );
    }

    /**
     * Emits an success toast with the given message and autodismiss.
     */
    public toastSuccess<T=any>( message:string, autoDismiss:number = 7500 ):ToastRef {
        return this.toaster.notifySuccess( message, autoDismiss );
    }

    /**
     * Emits an error notification through the view helper.
     *
     * @param error Can be any sort of error descriptor: a string, an API response, an AL-specific Error extension, a generic error, or an HTMLElement
     *          (but don't do that; it won't be pretty).  If it is null, any existing error will be cleared.
     *
     * @param blocking Indicates whether or not the error is a view-blocking error (in which case the full error trace and metadata will be exposed)
     *          or a non-blocking error.
     */
    public async setError( error:any = null, blocking:boolean = true, autoDismiss:number = 7500 ) {
        if ( ! error ) {
            return this.clearBlockingError();
        }

        const errorInfo = await this.errorTxr.describeError( error );
        this.errorTitle = errorInfo.title;
        this.errorDescription = errorInfo.description;
        this.errorDetails = errorInfo.details ? JSON.stringify( errorInfo.details, null, 4 ) : null;

        if ( blocking ) {
            this.showError = true;
        } else {
            this.showError = false;
            this.toaster.notifyError( `${this.errorDescription}`, autoDismiss );
        }
        this.refreshState();
    }

    public async setBlockingError( error:any ) {
        return await this.setError( error, true );
    }

    /**
     * @deprecated Please use `clearBlockingError()` instead.
     */
    public clearError() {
        this.clearBlockingError();
    }

    /**
     * Clears the error state
     */
    public clearBlockingError() {
        this.error = undefined;
        this.errorDescription = undefined;
        this.errorDetails = undefined;
        this.showError = false;
        this.showErrorDetails = false;
        this.refreshState();
    }

    /**
     * Clears the error state and calls the host-provided "try again" function
     */
    public attemptReload($event:Event) {
        if ( typeof( this.retryHandler ) === 'function' ) {
            console.warn("Notice: user has initiated a reload of the current view.  Clearing error state and retrying." );
            this.clearBlockingError();
            this.retryHandler();
        } else if ( typeof( this.retryHandler ) === 'boolean' ) {
            this.retry.emit();
        }
    }

    protected refreshState() {
        this.applyBlur = this.blur && this.loading; 
    }

}
