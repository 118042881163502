import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Route } from '@angular/router';
import { ButtonModule } from 'primeng-lts/button';
import { CardModule } from 'primeng-lts/card';
import { CheckboxModule } from 'primeng-lts/checkbox';
import { InputTextModule } from 'primeng-lts/inputtext';
import { TooltipModule } from 'primeng-lts/tooltip';
import { NgFormsComponentsModule } from '@al/ng-forms-components';
import { NgGenericComponentsModule } from '@al/ng-generic-components';
import { NgNavigationModule } from '@al/ng-navigation-components';
import { FactoryResetComponent } from './factory-reset/factory-reset.component';
import { FortraAuthenticationComponent } from './fortra-authentication/fortra-authentication.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { MFAVerificationComponent } from './mfa-verification/mfa-verification.component';
import { NothingComponent } from './nothing/nothing.component';
import { AlPasswordResetComponent } from './password-reset/password-reset.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        InputTextModule,
        NgGenericComponentsModule,
        NgFormsComponentsModule,
        NgNavigationModule,
        ButtonModule,
        CheckboxModule,
        TooltipModule,
        CardModule
    ],
    declarations: [
        LoginComponent,
        LogoutComponent,
        MFAVerificationComponent,
        TermsOfServiceComponent,
        AlPasswordResetComponent,
        FortraAuthenticationComponent,
        FactoryResetComponent,
        NothingComponent,
    ]
})
export class FeatureAuthModule {
    public static publicRoutes: Route[] = [
        {
            path: 'login',
            component: LoginComponent,
            data: {
                title: "Login"
            }
        },
        {
            path: 'logout',
            component: LogoutComponent,
            data: {
                title: "Logout",
            }
        },
        {
            path: 'mfa/verify',
            component: MFAVerificationComponent,
            data: {
                pageViewTitle: 'Accounts | MFA Verify'
            }
        },
        {
            path: 'terms-of-service',
            component: TermsOfServiceComponent,
            data: {
                pageViewTitle: 'Terms of Service'
            }
        },

        {
            path: 'password/reset',
            component: AlPasswordResetComponent,
            data: {
                pageViewTitle: 'Password Reset'
            }
        },
        {
            path: 'password/reset/confirm',
            component: AlPasswordResetComponent,
            data: {
                confirmChange: true,
                pageViewTitle: 'Password Reset'
            }
        },
        {
            path: 'password/reset/confirm/:token',
            component: AlPasswordResetComponent,
            data: {
                confirmChange: true,
                pageViewTitle: 'Password Reset'
            }
        },

        {
            path: 'fidp',
            component: FortraAuthenticationComponent,
            data: { pageViewTitle: 'Fortra SSO' }
        },
        {
            path: 'factory-reset',
            component: FactoryResetComponent,
            data: { pageViewTitle: "Console Factory Reset" }
        },
        {
            path: 'nothing',
            component: NothingComponent
        }
    ];
}
