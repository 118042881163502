import {
    Component, 
    EventEmitter, 
    Input, 
    Output, 
    SimpleChanges, 
    OnChanges, 
    ViewChild, 
    ViewEncapsulation
} from '@angular/core';
import { SelectItem } from 'primeng-lts/api';
import { SlideMenu } from 'primeng-lts/slidemenu';
import { AlSearchBarComponent } from '../al-search-bar/al-search-bar.component';
import { AlToolbarContentConfig, AlViewByItem } from '../types/al-generic.types';


@Component({
    selector: 'al-content-toolbar',
    templateUrl: './al-content-toolbar.component.html',
    styleUrls: ['./al-content-toolbar.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class AlContentToolbarComponent implements OnChanges {
    @Input() config: AlToolbarContentConfig;
    @Output() onOrderBy: EventEmitter<string> = new EventEmitter<string>();
    @Output() onSearched: EventEmitter<string> = new EventEmitter<string>();
    @Output() onFiltersSelection: EventEmitter<string[]> = new EventEmitter<string[]>();
    @Output() onSortSelection: EventEmitter<string> = new EventEmitter<string>();
    @Output() onGroupSelection: EventEmitter<string> = new EventEmitter<string>();
    @Output() onSelectAll: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onViewSelection: EventEmitter<string> = new EventEmitter<string>();
    @Output() onSettingSelection: EventEmitter<string> = new EventEmitter<string>();
    @Output() onInputSwitchChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('alSearchBar') alSearchBar: AlSearchBarComponent;
    @ViewChild('settingsMenu', { static: true }) settingsMenu?: SlideMenu;

    public state: AlToolbarContentConfig & { viewSelected: string } = {
        showSelectAll: false,
        selectAll: false,
        showFilters: false,
        filters: {
            label: "Select any filter(s)",
            options: [] as SelectItem[],
            selectedOptions: [] as string[],
            optionsMenu: {
                viewportHeight: 150,
                menuWidth: 300,
                style: "{'width':'310px'}"
            }
        },
        showSortBy: false,
        sort: {
            options: [] as SelectItem[],
            selectedOption: '',
            order: 'asc'
        },
        showGroupBy: false,
        group: {
            options: [] as SelectItem[],
            selectedOption: ''
        },
        showSearch: true,
        search: {
            maxSearchLength: 0,
            textPlaceHolder: ''
        },
        showViewBy: false,
        viewBy: [] as AlViewByItem[],
        showSettings: false,
        showInputSwitch: false,
        viewSelected: ''
    };

    public showDateSelector: boolean;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('config')) {
            this.addCommandToSettings();
            Object.assign(this.state, this.config);
            if (this.state.viewBy && this.state.viewBy.length) {
                this.state.viewBy.forEach(viewDef => {
                    if (viewDef.default) {
                        this.state.viewSelected = viewDef.value;
                    }
                });
            }
        }
    }

    public selectAllValues(): void {
        this.onSelectAll.emit(this.state.selectAll);
    }

    public sort(): void {
        this.state.sort.order = this.state.sort.order === 'asc' ? 'desc' : 'asc';
        this.onOrderBy.emit(this.state.sort.order);
    }

    public handleFilterSelection(selection?): void {
        if (this.state.filters.selectedOptions) {
            this.onFiltersSelection.emit(this.state.filters.selectedOptions);
        }
    }

    public handleSortSelection(): void {
        if (this.state.sort.selectedOption) {
            this.onSortSelection.emit(this.state.sort.selectedOption);
        }
    }

    public handleGroupSelection(): void {
        if (this.state.group.selectedOption) {
            this.onGroupSelection.emit(this.state.group.selectedOption);
        }
    }

    public handleViewSelection(): void {
        if (this.state.viewSelected) {
            this.onViewSelection.emit(this.state.viewSelected);
        }
    }

    public handleInputSwitchChange(): void {
        this.onInputSwitchChange.emit(this.state.inputSwitch.value);
    }


    public applyTextFilter(searchInput: string): void {
        this.onSearched.emit(searchInput);
    }

    public setTextFilter(searchInput: string): void {
        this.alSearchBar.searchControl.setValue(searchInput);
    }

    public uncheckAllOption(): void {
        this.state.selectAll = false;
    }

    public toggleSettings(event: MouseEvent): void {
        this.settingsMenu.toggle(event);
    }

    private addCommandToSettings(): void {
        if (this.config?.showSettings && this.config?.settings.options.length > 0) {
            this.config.settings.options = this.config.settings.options.map(opt => {
                opt.command = this.getSettingMenuCommand(opt.id);
                return opt;
            })
        }
    }

    private getSettingMenuCommand(id: string): () => void {
        return () => {
            this.onSettingSelection.emit(id);
        }

    }
}
