<div class="u-flex u-justify-between u-items-center u-mb-2"
     data-cy="content-toolbar">

  <div class="u-flex u-items-center">

    <div class="u-flex-grow-0 u-flex-shrink-0 u-w-18"
         *ngIf="state.showSelectAll">
      <p-checkbox [(ngModel)]="state.selectAll"
                  class="u-flex u-justify-center u-h-100% u-mr-0"
                  [binary]="true"
                  (onChange)="selectAllValues()"
                  data-cy="content-toolbar-toggle-checkbox">
      </p-checkbox>
    </div>

    <div class="u-flex u-items-center">
      <div *ngIf="state.showFilters && !state.filters?.grouped && state.filters.options?.length > 0"
           class="filters">
        <p-multiSelect class="u-mr-4 al-content-toolbar-multiselect"
                       [options]="state.filters.options"
                       [(ngModel)]="state.filters.selectedOptions"
                       (onChange)="handleFilterSelection()"
                       [defaultLabel]="state.filters.label"
                       selectedItemsLabel="{0} filter(s) selected">
        </p-multiSelect>
      </div>
      <div *ngIf="state.showFilters && state.filters?.grouped && state.filters.groupedOptions?.length > 0">
        <p-multiSelect class="u-mr-4 al-content-toolbar-multiselect"
                       [options]="state.filters.groupedOptions"
                       [group]="true"
                       (onChange)="handleFilterSelection($event)"
                       [(ngModel)]="state.filters.selectedOptions"
                       [defaultLabel]="state.filters.label"
                       scrollHeight="250px"
                       display="chip">
          <ng-template let-group
                       pTemplate="group">
            <div class="p-d-flex p-ai-center">
              <span> <b>{{group.label}} </b> </span>
            </div>
          </ng-template>
        </p-multiSelect>

      </div>
      <div *ngIf="state.showViewBy && state.viewBy?.length > 0">

        <p-dropdown [options]="state.viewBy"
                    [(ngModel)]="state.viewSelected"
                    (onChange)="handleViewSelection()"
                    [scrollHeight]="'unset'"
                    data-cy="content-toolbar-viewBy"
                    data-e2e="content-toolbar-viewBy">
          <ng-template let-item
                       pTemplate="selectedItem">
            <div class="u-flex u-items-center">
              <i *ngIf="item.icon?.name"
                 class="material-icons c-icon-sm">{{ item.icon?.name }}</i>
              <i *ngIf="item.icon?.cssClasses"
                 class=" c-icon-sm {{item.icon?.cssClasses}}"></i>
              <span class="u-text-sm u-ml-2">{{item.label}}</span>
            </div>
          </ng-template>
          <ng-template let-view
                       pTemplate="item">
            <div class="u-flex u-items-center"
                 data-cy="content-toolbar-viewBy-option"
                 data-e2e="content-toolbar-viewBy-option">
              <i *ngIf="view.icon?.name"
                 class="material-icons c-icon-sm">{{ view.icon?.name }}</i>
              <i *ngIf="view.icon?.cssClasses"
                 class="c-icon-sm {{view.icon?.cssClasses}}"></i>
              <div class="u-text-sm u-ml-2">{{view.label}}</div>
            </div>
          </ng-template>
        </p-dropdown>

      </div>

      <p-dropdown *ngIf="state.showGroupBy && state.group.options?.length > 0"
                  class="u-ml-4"
                  [options]="state.group.options"
                  [(ngModel)]="state.group.selectedOption"
                  (onChange)="handleGroupSelection()"
                  data-cy="content-toolbar-groupby"
                  data-e2e="content-toolbar-groupby"></p-dropdown>

      <p-dropdown *ngIf="state.showSortBy && state.sort.options?.length > 0"
                  class="u-ml-4"
                  data-cy="content-toolbar-sort"
                  [options]="state.sort.options"
                  [(ngModel)]="state.sort.selectedOption"
                  (onChange)="handleSortSelection()"
                  data-cy="content-toolbar-sort"
                  data-e2e="content-toolbar-sort">
      </p-dropdown>

      <i *ngIf="state.showSortBy"
         class="material-icons u-mx-4 u-my-0 u-cursor-pointer"
         (click)="sort()"
         [pTooltip]="state.sort.order === 'asc' ?  'ascending' : 'descending'">
        swap_vert
      </i>

    </div>

  </div>


  <al-search-bar #alSearchBar
                 *ngIf="state.showSearch"
                 [placeholder]="state.search.textPlaceHolder"
                 [maxSearchLength]="state.search.maxSearchLength"
                 (onSearchChanged)="applyTextFilter($event)">
  </al-search-bar>

  <div *ngIf="state.showInputSwitch">
    <span> {{state.inputSwitch.label}} </span>
    <p-inputSwitch [(ngModel)]="state?.inputSwitch.value"
                   (onChange)="handleInputSwitchChange()">
    </p-inputSwitch>
  </div>




  <button *ngIf="state.showSettings"
          pButton
          pRipple
          type="button"
          class="settings-button"
          icon="pi pi-cog"
          [style.background-color]="'white'"
          [style.color]="'black'"
          (click)="toggleSettings($event)">
  </button>




</div>

<p-slideMenu #settingsMenu
             appendTo="body"
             [model]="state?.settings?.options ?? []"
             [popup]="true"
             [viewportHeight]="state?.filters?.optionsMenu?.viewportHeight"
             [menuWidth]="state?.filters?.optionsMenu?.menuWidth"
             [style]="state?.filters?.optionsMenu?.style">
</p-slideMenu>