/**
*
* AlGlobalErrorService
*
* This Service catch all the Javascript Errors in the UI and send metrics
* to Google Analytics using the "track" method.
*
*  @author Fair Tarapues <fair.tarapues@alertlogic.com>
*  @copyright Alert Logic Inc, 2020
*/

import { datadogRum } from '@datadog/browser-rum';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { AlLocatorService } from '@al/core';
import { AlTrackingMetricEventName, AlTrackingMetricEventCategory, AlErrorTranslatorService } from '@al/ng-generic-components';
import { AlNavigationService } from './al-navigation.service';


@Injectable()
export class AlGlobalErrorService extends ErrorHandler {
    constructor( private injector:Injector ) {
        super();
    }

    handleError(error: any) {
        const navigation = this.injector.get(AlNavigationService);
        const errorTxr = this.injector.get(AlErrorTranslatorService);
        if ( ['integration', 'development'].includes(AlLocatorService.getCurrentEnvironment()) ) {
            console.error(error);
        }
        let label:string = "Unknown Error";
        if ( error.stack ) {
            label = error.stack.replace(/"X-AIMS-Auth-Token"\s*:\s*"[A-Za-z0-9\-\._~\+\/\=]+=*"/gmi,'"X-AIMS-Auth-Token":"..."');
        } else if ( error.message ) {
            label = error.message.replace(/"X-AIMS-Auth-Token"\s*:\s*"[A-Za-z0-9\-\._~\+\/\=]+=*"/gmi,'"X-AIMS-Auth-Token":"..."');
        } else if ( error.status && error.config?.method && error.config?.url ) {
            label = `Received ${error.status} response from ${error.config.method} [${error.config.url}]`;
        }

        if ( navigation ) {
            navigation.track(AlTrackingMetricEventName.UsageTrackingEvent, {
                category: AlTrackingMetricEventCategory.GenericConsoleException,
                action: "Javascript Error",
                label: label
            });
        }
        if ( AlLocatorService.getCurrentEnvironment() === 'integration' ) {
            datadogRum.addError( errorTxr.redact( error ) );
        }
    }
}
