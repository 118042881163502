import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {
    AlErrorHandler, 
    AlLocatorService, 
    AlRuntimeConfiguration, 
    ConfigOption, 
    AlLocation,
    AlIdentityProviders
} from '@al/core';
import { AlNavigationService } from '@al/ng-navigation-components';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as versionInfo from './version-info';

async function bootstrap() {
    AlErrorHandler.verbose = true;
    AlRuntimeConfiguration.setOption( ConfigOption.GestaltDomain, AlLocation.MagmaUI );
    AlRuntimeConfiguration.setOption( ConfigOption.GestaltAuthenticate, true );
    AlRuntimeConfiguration.setOption( ConfigOption.NavigationConduitLocation, AlLocation.MagmaUI );

    //  Enable the following to use static content from your local environment
    /*
    AlRuntimeConfiguration.useLocalContent();
    */

    //  Enable the following lines to access production from your local environment
    /*
    AlRuntimeConfiguration.setContext('production')
    */

    AlRuntimeConfiguration.setParamPreservationRules( {
        'log-messages': {
            applyTo: [ /search\/log\/*/ ],
            volatile: [ 'log_id', 'where' ]
        },
        'search-expert': {
            applyTo: [ /search\/expert\/*/ ],
            volatile: [ 'sql', 'mode', 'timeframe', 'datatype', 'ems', 'ingest_id' ]
        },
        'correlations': {
            applyTo: [ /search\/correlations\/*/ ],
            volatile: [ 'query' ]
        },
        'search-templates': {
            applyTo: [ /search\/search-templates\/*/ ],
            volatile: [ 'sort_by','sort_direction','data_type' ]
        }
    } );

    /**
     * Parameter preservarion rules for Automated Response
     */
    const automatedResponseFilters = [
        'statuses',
        'playbook_ids',
        'playbook_types',
        'deployments',
        'display_names',
        'types',
        'startDate',
        'endDate',
        'trigger_ids',
        'action_names',
        'action_display_names',
        'type',
        'enabled',
        'vendors',
        'category',
        'status',
        'bk_location_type',
        'bk_location_id',
        'trigger_types',
        'task_names',
        'response',
        'query'
    ];

    AlRuntimeConfiguration.setParamPreservationRules({
        'playbook-history': {
            applyTo: [
                /^\/automated-response\/history\/.*/,
                /^\/automated-response\/history-detail\/.*/
            ],
            volatile: automatedResponseFilters
        },
        'playbook-inquiries': {
            applyTo: [
                /^\/automated-response\/inquiries\/.*/
            ],
            volatile: automatedResponseFilters
        },
        'playbook-triggers': {
            applyTo: [
                /^\/automated-response\/triggers\/.*/
            ],
            volatile: automatedResponseFilters
        },
        'playbooks': {
            applyTo: [
                /^\/automated-response\/playbooks\/.*/
            ],
            volatile: automatedResponseFilters
        },
        'simple-responses': {
            applyTo: [
                /^\/automated-response\/simple-responses\/.*/
            ],
            volatile: automatedResponseFilters
        },
        'simple-history': {
            applyTo: [
                /^\/automated-response\/simple-history\/.*/
            ],
            volatile: automatedResponseFilters
        }
    });

    const assetsFilters = ['type', 'deployment.name', 'groups', 'deployment.platform.type', 'protection_policy_name', 'search', 'health_level', 'related_properties.vpc[0].name'];
    AlRuntimeConfiguration.setParamPreservationRules({
        'assets-hosts': {
            applyTo: [/assets\/hosts\/*/],
            volatile: assetsFilters
        },
        'assets-subnets': {
            applyTo: [/assets\/subnets\/*/],
            volatile: assetsFilters
        },
        'assets-networks': {
            applyTo: [/assets\/networks\/*/],
            volatile: assetsFilters
        },
        'assets-regions': {
            applyTo: [/assets\/regions\/*/],
            volatile: assetsFilters
        },
        'assets-deployments': {
            applyTo: [/assets\/deployments\/*/],
            volatile: assetsFilters
        }
    });

    const incidentsFilters = ['tab', 'subtab'];
    AlRuntimeConfiguration.setParamPreservationRules({
        'incident-details': {
            applyTo: [/^\/incidents\//],
            volatile: incidentsFilters
        },
    });

    /**
     * Parameter preservarion rules for Manage Notifications
     */
    const manageNotigicationsFilters = ["subscription_id"];

    AlRuntimeConfiguration.setParamPreservationRules({
        'alert-notifications': {
            applyTo: [/manage-notifications\/\d+\/alerts/],
            volatile: manageNotigicationsFilters
        },
        'scheduled-notifications': {
            applyTo: [/manage-notifications\/\d+\/scheduled\-actions/],
            volatile: manageNotigicationsFilters
        }
    });

    /**
     * Parameter preservarion rules for Recommendations
     */
    const recommendationsFilters = [
        "deployment_id",
        "category",
        "region",
        "vpc",
        "subnet",
        "tag",
        "auto-scaling-group",
        "image",
        "load-balancer",
        "sg",
        "deployment_type",
        "dns-zone",
        "group",
        "host",
        "instance-profile",
        "launch-config",
        "redshift-cluster",
        "role",
        "s3-bucket",
        "severity",
        "user",
        "collector",
        "search",
        "affected_asset"
    ];

    AlRuntimeConfiguration.setParamPreservationRules({
        'open': {
            applyTo: [/exposure-management\/recommendations\/open/],
            volatile: recommendationsFilters
        },
        'disposed': {
            applyTo: [/exposure-management\/recommendations\/disposed/],
            volatile: recommendationsFilters
        },
        'concluded': {
            applyTo: [/exposure-management\/recommendations\/concluded/],
            volatile: recommendationsFilters
        }
    });

    AlRuntimeConfiguration.setParamPreservationRules({
        'open': {
            applyTo: [/exposures\/open/],
            volatile: recommendationsFilters
        },
        'disposed': {
            applyTo: [/exposures\/disposed/],
            volatile: recommendationsFilters
        },
        'concluded': {
            applyTo: [/exposures\/concluded/],
            volatile: recommendationsFilters
        }
    });


    AlNavigationService.appVersionId = versionInfo.versionId;

    let idProviders = new AlIdentityProviders();

    await idProviders.warmup();     //  This allows our authentication providers to access the URL before @angular's router has a chance to modify it

    if (environment.production) {
        enableProdMode();
    }

    platformBrowserDynamic().bootstrapModule(AppModule)
                            .catch(err => console.error(err));
}

bootstrap();
