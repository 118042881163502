/**
 *  @author Kevin Nielsen <knielsen@alertlogic.com>
 *  @author Robert Parker <robert.parker@alertlogic.com>
 *
 *  @copyright Alert Logic, Inc 2019
 */

import { WebAuth } from 'auth0-js';
import { Injectable } from '@angular/core';
import {
    AIMSClient,
    AIMSSessionDescriptor,
    AlActingAccountResolvedEvent,
    AlSessionStartedEvent,
    AlSessionEndedEvent,
    AlAuthenticationUtility,
    AlAuthenticationResult,
    AlBehaviorPromise,
    AlCabinet,
    AlConduitClient,
    AlDefaultClient,
    AlErrorHandler,
    AlGlobalizer,
    AlLocation,
    AlLocatorService,
    AlRuntimeConfiguration,
    AlSession,
    AlStopwatch,
    ConfigOption,
    FortraSession,
    AlFortraTokenChangedEvent,
    AlIdentityProviders,
} from '@al/core';
import { AlMiniConduit } from '../types/al-mini-conduit';


import Keycloak, { KeycloakLoginOptions, KeycloakOnLoad } from 'keycloak-js';

@Injectable({
    providedIn: 'root'
})
export class AlSessionManagerService
{
    /**
     *  Indicates whether or not this authentication provider is currently authenticated.
     */
    public authenticated:boolean = false;

    public conduit = new AlConduitClient();
    public echoConduit?:AlMiniConduit;

    /**
     *  Keycloak and Auth0 client instances
     */
    protected auth0Client:WebAuth = undefined;
    protected cloak:Keycloak;
    protected cloakPromise = new AlBehaviorPromise();
    protected conduitSync?:AlStopwatch;
    protected providers = new AlIdentityProviders();

    /**
     * If session detection is currently executing, this is the observable in progress.
     */
    protected detectionPromise:Promise<boolean> = null;

    /**
     * Cached userInfo (this holds data from auth0's userInfo endpoint, keyed by access token)
     */
    protected cachedA0UserInfo:{[accessKey:string]:any} = {};

    protected storage = AlCabinet.persistent("alnav");

    protected lastSyncedToken?:string;
    protected lastSyncedExpiry?:number;
    protected allIsLost = false;

    constructor() {
        AlSession.notifyStream.attach( AlActingAccountResolvedEvent, () => this.onSessionChangeEvent() );
        AlSession.notifyStream.attach( AlSessionStartedEvent, () => this.onSessionChangeEvent() );
        AlSession.notifyStream.attach( AlSessionEndedEvent, () => this.onSessionChangeEvent() );
        AlDefaultClient.setBeforeRequest( () => this.refreshAccessToken() );
        this.conduitSync = AlStopwatch.once( this.synchronizeCurrentSession );
        AlGlobalizer.expose( "al.session.manager", {
            detect: () => this.detectSession(),
            destroy: () => this.destroySession()
        } );
    }

    public onSessionChangeEvent() {
        this.conduitSync?.reschedule( 500 );
    }

    /**
     *  Checks to see if a session already exists.
     *  If a session exists or is discovered, the observable emits `true` and internal state is guaranteed to be authenticated and properly populated.
     *  If no session is found, the observable emits `false` and internal state is guaranteed to be clean and unauthenticated.
     *
     *  @param {string} preferredActingAccountId - If provided and there is no current session, this accountId will be used instead of the default/primary.
     */

    public async detectSession( preferredActingAccountId:string = null ): Promise<boolean> {

        if ( ! this.detectionPromise ) {
            this.detectionPromise = new Promise( ( resolve, reject ) => {
                this.innerDetectSession( resolve, reject );
            } );
        }

        return this.detectionPromise;
    }

    /**
     * Imperatively destroys a session -- includes local session state, defender authentication state and cookies,
     * and conduit session cache.  Optionally redirects upon completion (pass false as the `returnURL` parameter to
     * suppress redirection).
     */
    public async destroySession( returnURL?:string|boolean ) {
        /**
         *  Forcibly end any extant defender sessions.
         */
        let session = AlSession.getSession();
        const currentEnvironment = AlLocatorService.getContext().environment;
        const defenderNodes = {};

        AlLocatorService.search( node => {
            if ( node.locTypeId === AlLocation.LegacyUI && node.environment === currentEnvironment ) {
                defenderNodes[node.uri] = node.uri;
                return true;
            }
            return false;
        } );
        const requestArray = [];
        if ( AlRuntimeConfiguration.getOption( ConfigOption.GestaltAuthenticate ) ) {
            const environment = currentEnvironment === 'development' ? 'integration' : currentEnvironment;
            requestArray.push( AlDefaultClient.delete( {
                url: AlLocatorService.resolveURL( AlLocation.MagmaUI, `/session/v1/status`, { environment } ),
                withCredentials: true
            } ) );
        }
        Object.values( defenderNodes ).forEach( defenderNodeURI => {
            const logoutURL = `${defenderNodeURI}/defender_unified_logout.php`;
            requestArray.push( AlDefaultClient.get( { url: logoutURL } ) );
        } );

        if ( requestArray.length > 0 ) {
            let results = await Promise.allSettled( requestArray );
        }
        await this.innerDestroySession( returnURL );
    }

    /**
     * Returns a promise that either resolves immediately (if no session detection is in process) or resolves after
     * an in-progress detection cycle has completed.  This allows the caller to wait for detection to finish without
     * starting an unwanted detection cycle.
     */

    public async detectionComplete():Promise<boolean> {
        if ( this.detectionPromise ) {
            await this.detectionPromise;
        }
        return AlSession.isActive();
    }

    /**
     *  Imperatively forces the user to authenticate via Fortra IdP.
     */
    public async forceFortraLogin( rawReturnURL?:string|boolean, userEmail?:string ) {
        const authenticationRoutes = [
            "/#/login",
            "/#/mfa/",
            "/#/terms-of-service",
            "/#/factory-reset",
            "/#/logout"
        ];
        let returnURL:string = typeof( rawReturnURL ) === 'string'
                                    ? rawReturnURL
                                    : window.location.href;
        if ( authenticationRoutes.some( routePattern => returnURL.includes( routePattern ) ) ) {
            returnURL = AlLocatorService.resolveURL( AlLocation.MagmaUI, '/#/' );   //  don't bother returning to these URLs, just start at the top
        }

        const cloak = await this.providers.getKeycloak();
        if ( ! cloak ) {
            AlErrorHandler.report( new Error( `Cannot trigger fortra authentication` ) );
            return;
        }
        const options:KeycloakLoginOptions = { redirectUri: returnURL };
        if ( userEmail ) {
            try {
                let residency = 'US';
                let environment = AlLocatorService.getCurrentEnvironment();
                if ( environment === 'development' ) {
                    environment = 'integration';
                }
                let emailMapURL = AlLocatorService.resolveURL( AlLocation.MagmaUI, `/session/v1/identity-map`, { residency, environment } );
                let emailMapping = await AlDefaultClient.post( {
                    url: emailMapURL,
                    withCredentials: false,
                    data: { email: userEmail }
                } );
                userEmail = 'email' in emailMapping ? emailMapping.email : userEmail;
            } catch( e ) {
                AlErrorHandler.log( e );
            }
            options.loginHint = userEmail;
        }
        if ( ! this.allIsLost ) {
            cloak.login( options );
        }
    }

    /**
     * Determine whether or not the current session should be shown the fortra SSO splash screen after login.
     * This should happen if the primary account has the `fortra_authenticated` property set and the current user has NOT
     * logged in via Fortra before.
     */
    public useFortraEntry():boolean {
        const session = AlSession.getSession();
        if (    session                                                         /* session exists */
                && session.authentication?.account?.fortra_authenticated        /* account should migrate to fortra */
                && ! AlSession.getFortraSession()                               /* current session is NOT a fortra session */
                && ! this.hasFortraIdentity() )                                 /* user has not logged in via Fortra before (so far as we know) */
        {
            const fortraIdentity = session.authentication?.user.linked_users.find( u => u.location === 'fortra-idp' );
            if ( fortraIdentity ) {
                //  Last but not least, ensure that the current user has a provisioned fortra identity -- absence implying that the current user
                //  is not ready to migrate even though other users in this account are.
                return true;
            }
        }
        return false;
    }

    /**
     * Imperatively destroys the Fortra keycloak session.
     */
    public async forceFortraLogout( rawReturnURL?:string|boolean ) {
        let returnURL:string = typeof( rawReturnURL ) === 'string'
                                    ? rawReturnURL
                                    : AlLocatorService.resolveURL( AlLocation.MagmaUI, '/#/' );
        const cloak = await this.providers.getKeycloak();
        if ( ! cloak || ! cloak.authenticated ) {
            AlErrorHandler.report( new Error( `Cannot trigger fortra deauthentication from an unauthenticated state` ) );
            this.forceFortraLogin();
            return;
        }
        cloak.clearToken();
        cloak.logout( { redirectUri: returnURL } );
    }

    /**
     * Currently, this relies on local storage and keycloak to determine whether or not the user has a working fortra identity -- e.g., the proof is
     * in the pudding.  Ideally, this is something we should be able to determine via AIMS to avoid this act of guesswork.
     */
    public hasFortraIdentity() {
        return this.storage.get("fortra_authenticated", false );
    }

    public async recordSessionStart() {
        let currentToken = AlSession.getToken();
        let lastRecordedToken = this.storage.get("lastRecordedSession", "" );
        if ( currentToken !== lastRecordedToken ) {
            await AIMSClient.recordSessionStart( AlSession.getPrimaryAccountId(), AlSession.getUserId() );
            this.storage.set("lastRecordedSession", currentToken );
        }
    }

    public async refreshAccessToken() {
        try {
            const cloak = await this.providers.getKeycloak();
            if ( cloak && cloak.token ) {
                const refreshed = await cloak.updateToken( 90 );        //  90 seconds of runway
                if ( refreshed || ! AlSession.isActive() || AlSession.getToken() !== cloak.token ) {
                    AlErrorHandler.log( `Note: synchronizing updated access token.` );
                    AlSession.setTokenInfo( cloak.token, AlSession.getTokenExpiry(), cloak.idToken, cloak.refreshToken );
                    AlSession.notifyStream.trigger( new AlFortraTokenChangedEvent(cloak.token) );
                    this.conduitSync.reschedule( 0 );
                }
            }
        } catch( e ) {
            AlErrorHandler.log( e, `Unexpected error occurred while refreshing access token`);
        }
    }

    async innerDetectSession( resolve:any, reject:any ) {

        await AlSession.ready();        //  always wait for stable session state before detection starts

        AlSession.startDetection();

        /**
         * Does AlSession say we're active?  If so, then yey!
         */
        if ( AlSession.isActive() ) {
            return this.onDetectionSuccess( resolve, "local storage" );
        }

        /**
         * Check conduit to see if it has a session available
         */
        let session:AIMSSessionDescriptor = await this.conduit.getSession();
        if ( session && typeof( session ) === 'object' && this.sessionIsValid( session ) ) {
            try {
                await this.ingestExistingSession( session );
                return this.onDetectionSuccess( resolve, "conduit" );
            } catch ( e ) {
                await this.conduit.deleteSession();
                return this.onDetectionFail( resolve, "Conduit session could not be ingested; destroying it and triggering unauthenticated access handling.");
            }
        }

        /**
         * Can Gestalt's session status endpoint confirm we have a session?
         */
        if ( AlRuntimeConfiguration.getOption( ConfigOption.GestaltAuthenticate, false ) ) {
            try {
                let session = await this.getGestaltSession();
                if ( session ) {
                    await this.ingestExistingSession( session );
                    return this.onDetectionSuccess( resolve, "gestalt/session API" );
                }
            } catch( e ) {
                console.error( 'Unexpected error encountered while attempting to get session status from Gestalt; falling through.', e );
            }
        }

        /**
         * Are we authenticated via Fortra's Identity Provider?
         */
        try {
            let authenticated = await this.getFortraSession();
            if ( authenticated ) {
                return this.onDetectionSuccess( resolve, "Fortra IdP" );
            }
        } catch( e ) {
            AlErrorHandler.log( `Unexpected error encountered while attempting to detect Fortra IdP session; falling through.`, e );
        }

        /**
         * Can Auth0 give us a session?
         */
        try {
            let authenticator   =   this.getAuth0Authenticator();
            if ( authenticator ) {
                let config          =   this.getAuth0Config( { usePostMessage: true, prompt: 'none' } );
                let accessToken     =   await this.getAuth0SessionToken( authenticator, config, 5000 );
                let tokenInfo       =   await AIMSClient.getTokenInfo( accessToken );

                /**
                 * The following rather obscure assignment is necessary because aims' token_info endpoint responds with the complete token information *except* the token itself
                 */
                session = {
                    authentication: Object.assign({}, tokenInfo, {token: accessToken})
                };

                await this.ingestExistingSession( session );
                this.onDetectionSuccess( resolve, "Auth0 IdP" );
            } else {
                return this.onDetectionFail( resolve, `auth0 is not installed` );
            }
        } catch( e ) {
            let error = AlErrorHandler.normalize( e );
            AlErrorHandler.log( `Unexpected error encountered while attempt to detect auth0 session; falling through.`, e );
        }

        return this.onDetectionFail( resolve );
    }

    protected async innerDestroySession( returnURI?:string|boolean ) {
        const cloak                 = await this.providers.getKeycloak();
        const auth0Node             = AlLocatorService.getNode( AlLocation.Auth0 );
        const currentEnvironment    = AlLocatorService.getCurrentEnvironment();
        const isFortraSession       = AlSession.getFortraSession() && cloak && cloak.authenticated;

        const deauthenticateViaAuth0 = ! isFortraSession
                                        && auth0Node
                                        && [ 'production', 'integration' ].includes( currentEnvironment )
                                        && ! ( typeof( returnURI ) === 'string' && returnURI.includes( "account-pr-") );

        if ( deauthenticateViaAuth0 && typeof( returnURI ) === 'string' ) {
            const clientId    =   auth0Node.data.clientID;
            returnURI = "https://" + auth0Node.uri + "/v2/logout?federated&clientID=" + clientId + "&returnTo=" + encodeURIComponent( returnURI );
        }

        await this.detectionComplete();
        AlSession.deactivateSession();
        await this.synchronizeCurrentSession( true );

        if ( isFortraSession ) {
            this.forceFortraLogout( returnURI );
        } else if ( typeof( returnURI ) === 'string' ) {
            window.location.href = returnURI;
        }
    }

    async getGestaltSession():Promise<AIMSSessionDescriptor|null> {
        let residency = 'US';
        let environment = AlLocatorService.getCurrentEnvironment();
        if ( environment === 'development' ) {
            environment = 'integration';
        }
        let sessionStatusURL = AlLocatorService.resolveURL( AlLocation.MagmaUI, `/session/v1/status`, { residency, environment } );
        let sessionStatus = await AlDefaultClient.get( {
            url: sessionStatusURL,
            withCredentials: true
        } );
        if ( ! ( 'session' in sessionStatus ) || sessionStatus.session === null ) {
            return null;
        }
        let sessionDescriptor = {
            authentication: sessionStatus.session || {}
        };
        if ( this.sessionIsValid( sessionDescriptor ) ) {
            return sessionDescriptor;
        }
        throw new Error("Gestalt session is invalid." );
    }

    /**
     *  Given an AIMSAuthentication object -- which defines the token, user, and account whose session is being
     *  referenced -- this method will collect any missing data elements
     */

    ingestExistingSession = async ( proposedSession: AIMSSessionDescriptor ):Promise<boolean> => {
        let session = await this.normalizeSessionDescriptor( proposedSession );
        try {
            if ( session.fortraSession ) {
                const cloak = await this.providers.getKeycloak();
                if ( cloak && cloak.token ) {
                    const refreshed = await cloak.updateToken( 90 );        //  90 seconds of runway
                    if ( refreshed ) {
                        session.authentication.token = cloak.token;
                        AlSession.notifyStream.trigger( new AlFortraTokenChangedEvent(cloak.token) );
                    }
                }
            }
            await AlSession.setAuthentication( session );
            this.authenticated = AlSession.isActive();
            return true;
        } catch( e ) {
            this.authenticated = false;
            console.error("Failed to ingest session: ", e );
            throw new Error( e.toString() );
        }
    }

    /**
     * Checks to see if a session is currently active
     */
    sessionIsValid( proposed: AIMSSessionDescriptor ):boolean {
        if ( 'authentication' in proposed
                && 'token' in proposed.authentication
                && 'token_expiration' in proposed.authentication ) {
            if ( proposed.authentication.token_expiration > Date.now() / 1000 ) {
                return true;
            }
        }
        return false;
    }

    /**
     * Handles promise-based session-detection success (resolve true)
     */

    onDetectionFail = ( resolve:{(error:any):any}, warning:string = null ) => {
        if ( warning ) {
            AlErrorHandler.log( warning, `Could not detect session` );
        }
        this.authenticated = false;
        this.detectionPromise = null;
        AlSession.endDetection();
        resolve( false );
    }


    /**
     * Handles promise-based session-detection failure (resolve false)
     */

    onDetectionSuccess = async ( resolve:{(result:any):any}, sessionOrigin?:string ) => {
        const token = AlSession.getToken();
        AlErrorHandler.log( `SessionMgr: detected valid session [${token.substring( token.length - 8 )}/${AlSession.getTokenExpiry()}] (origin: ${sessionOrigin})` );
        this.authenticated = true;
        this.detectionPromise = null;
        AlSession.endDetection();
        resolve( true );
    }

    /**
     * Normalizes session data.
     */
    normalizeSessionDescriptor( session:AIMSSessionDescriptor ):Promise<AIMSSessionDescriptor> {
        return new Promise<AIMSSessionDescriptor>( ( resolve, reject ) => {
            if ( ! session.authentication.hasOwnProperty('token_expiration') || session.authentication.token_expiration === null ) {
                session.authentication.token_expiration = this.getTokenExpiration( session.authentication.token );
            }
            if ( session.authentication.user && session.authentication.account ) {
                return resolve( session );
            }
            AIMSClient.getTokenInfo( session.authentication.token )
                .then(  tokenInfo => {
                            if ( typeof( tokenInfo.user ) === 'object' ) {
                                session.authentication.user = tokenInfo.user;
                            }
                            if ( typeof( tokenInfo.account ) === 'object' ) {
                                session.authentication.account = tokenInfo.account;
                            }
                            if ( tokenInfo.token_expiration ) {
                                session.authentication.token_expiration = tokenInfo.token_expiration;
                            }
                            return resolve( session );
                        },
                        error => {
                            reject( error );
                        } );
        } );
    }

    async getFortraSession() {
        try {
            const cloak = await this.providers.getKeycloak();
            if ( cloak?.token ) {
                const fortraSession:FortraSession = {
                    accessToken: cloak.token,
                    identityToken: cloak.idToken,
                    refreshToken: cloak.refreshToken
                };
                let authUtility = new AlAuthenticationUtility();
                let result = await authUtility.authenticateFromFortraSession(fortraSession);
                if ( result === AlAuthenticationResult.Authenticated ) {
                    return true;
                }
            }
        } catch( e ) {
            AlErrorHandler.log( "Keycloak initialization threw error", e );
        }
        return false;
    }

    /**
     * Calculates the correct auth0 configuration to use.
     */
    getAuth0Config( merge:any = {} ):any {
        let w = <any>window;
        let auth0Node = AlLocatorService.getNode( AlLocation.Auth0 );
        if ( ! auth0Node || ! auth0Node.data || ! auth0Node.data.hasOwnProperty( 'clientID' ) ) {
            throw new Error("Service matrix does not reflect an auth0 node; check your app configuration." );
        }
        return Object.assign(   {
                                    domain:         auth0Node.uri,
                                    clientID:       auth0Node.data.clientID,
                                    responseType:   'token id_token',
                                    audience:       'https://alertlogic.com/',
                                    scope:          'openid user_metadata',
                                    prompt:         true,
                                    redirectUri:    w.location.origin
                                },
                                merge );
    }

    /**
     * Retrieve a reference to the Auth0 web auth instance.  This code is excluded from unit testing.
     */
    /* istanbul ignore next */
    getAuth0Authenticator():WebAuth {
        if ( this.auth0Client === undefined ) {
            /* Because Auth0 persists itself as a global, we will need to cast it from <any>window.auth.  Fun stuff :/ */
            let w = <any>window;
            if ( ! w.auth0 ) {
                console.warn( "Could not find the auth0 global object; is Auth0 installed?" );
                this.auth0Client = null;
                return null;
            }
            let authenticator = <WebAuth>new w.auth0.WebAuth( this.getAuth0Config() );
            if ( ! authenticator.hasOwnProperty("client" ) ) {
                //  Stop for this error, bad build?
                throw new Error("auth0.WebAuth instance does not have a client property; wrong version perhaps?" );
            }
            this.auth0Client = authenticator;
        }
        return this.auth0Client;
    }

    getAuth0UserInfo = ( authenticator:WebAuth, userAccessToken:string, callback:(error:any, userInfo:any)=>void ) => {
        if ( this.cachedA0UserInfo.hasOwnProperty( userAccessToken ) ) {
            callback( null, this.cachedA0UserInfo[userAccessToken] );
            return;
        }

        authenticator.client.userInfo( userAccessToken, ( userInfoError, userIdentityInfo ) => {
            if ( ! userInfoError && userIdentityInfo ) {
                this.cachedA0UserInfo[userAccessToken] = userIdentityInfo;        //  cache
            }
            callback( userInfoError, userIdentityInfo );
        } );
    }

    /**
     *  Extracts necessary data from the response to auth0's getUserInfo endpoint
     */
    extractUserInfo = ( identityData:any ) => {
        let config = this.getAuth0Config();
        let auth0Node = AlLocatorService.getNode( AlLocation.Auth0 );
        if ( ! auth0Node || ! auth0Node.data || ! auth0Node.data.hasOwnProperty( "clientID" ) ) {
            throw new Error("Configuration's service list does not include an entry for auth0 with a 'clientID' property!; check your configuration." );
        }
        let domainIdInfo    =   "";

        if ( identityData.hasOwnProperty( config.audience ) ) {
            domainIdInfo = identityData[config.audience].sub;
        } else {
            throw new Error(`Unexpected identity data received from auth0; no audience '${config.audience}' found.` );
        }

        let userInfo = domainIdInfo.split(":");
        if ( userInfo.length !== 2 ) {
            throw new Error(`Unexpected identity data received from auth0; audience '${config.audience}' contains unexpected content '${domainIdInfo}'.` );
        }

        let accountId       =   userInfo[0];
        let userId          =   userInfo[1];
        if ( ! accountId || ! userId ) {
            throw new Error(`Unexpected identity data received from auth0; audience '${config.audience}' contains empty account or user in '${domainIdInfo}'.` );
        }
        return {
            "accountId": accountId,
            "userId": userId
        };
    }

    /**
     * Given a token, determine its expiration timestamp in seconds.
     */
    getTokenExpiration( token:string ) {
        const split = token.split('.');
        if (!split || split.length < 2 ) {
            console.warn("Warning: unexpected JWT format causing existing session not to be recognized.", token );
            return 0;
        }
        const base64Url = split[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        let userData;
        try {
            userData = JSON.parse(window.atob(base64));
        } catch (e) {
            console.warn("Warning: invalid JWT encoding causing existing session not to be recognized." );
            return 0;
        }

        if (!('exp' in userData)) {
            console.warn("Warning: invalid JWT user data causing existing session not to be recognized." );
            return 0;
        }

        return userData.exp;
    }

    /**
     * Uses a race to make sure that auth0 session detection doesn't time out -- since a misconfigured client can cause the
     * promise to hang indefinitely.
     */
    protected async getAuth0SessionToken( authenticator:WebAuth, config:any, timeout:number = 5000 ):Promise<string> {
      return Promise.race( [ AlStopwatch.promise( timeout ),
                             new Promise<string>( ( resolve, reject ) => {
                               authenticator.checkSession( config, ( error, authResult ) => {
                                   if ( error || ! authResult || ! authResult.accessToken ) {
                                       reject("auth0's checkSession method failed with an error" );
                                   } else {
                                       resolve( authResult.accessToken );
                                   }
                               } );
                           } ) ] )
                      .then( ( accessToken:string|any ) => {
                          if ( accessToken && typeof( accessToken ) === 'string' ) {
                              return accessToken;
                          }
                          return Promise.reject("checkSession returned false or could not complete execution before timeout." );
                      } );
    }

    /**
     * Synchronizes session state to one or more conduit instances and gestalt's proxy endpoint, depending on configuration.
     */
    protected synchronizeCurrentSession = async( forceSync?:boolean ) => {
        const thisToken = AlSession.isActive() ? AlSession.getToken() : undefined;
        const thisExpiry = AlSession.isActive() ? AlSession.getTokenExpiry() : undefined;
        const syncGestalt = AlRuntimeConfiguration.getOption( ConfigOption.GestaltAuthenticate, false );

        try {
            if ( thisToken === this.lastSyncedToken && thisExpiry === this.lastSyncedExpiry && ! forceSync ) {
                //  Debounce redundant synchronization efforts
                return;
            }

            const magmaConduit = AlRuntimeConfiguration.getOption( ConfigOption.GestaltDomain ) === AlLocation.MagmaUI;
            this.conduit.start();
            if ( magmaConduit && ! this.echoConduit ) {
                let environment = AlLocatorService.getCurrentEnvironment();
                if ( environment === 'development' ) {
                    environment = 'integration';
                }
                this.echoConduit = new AlMiniConduit( AlLocatorService.resolveURL( AlLocation.AccountsUI, `/conduit.html`, { environment } ) );
                this.echoConduit.start();
            }

            let actions:Promise<any>[] = [];
            if ( AlSession.isActive() ) {
                actions.push( this.conduit.setSession( AlSession.getSession() ) );
                if ( magmaConduit ) {
                    actions.push( this.echoConduit?.setSession( AlSession.getSession() ) );
                } else {
                    localStorage.setItem("conduit.session", JSON.stringify( AlSession.getSession() ) );
                }
                if ( syncGestalt ) {
                    //  This syncronizes the updated session (specifically, the current access token) to gestalt, exposed via the main console app.
                    //  This prevents "sticky" sessions with expired access tokens from making everyone's life miserable, but allows us to continue
                    //  using the 1st party cookie that is set there.
                    const currentEnvironment    =   AlLocatorService.getCurrentEnvironment();
                    const environment           =   currentEnvironment === 'development' ? 'integration' : currentEnvironment;
                    const url                   =   AlLocatorService.resolveURL( AlLocation.MagmaUI, `/session/v1/status`, { environment } );
                    actions.push( AlDefaultClient.put( { url, withCredentials: true, data: AlSession.getSession() } ) );
                }
            } else {
                actions.push( this.conduit.deleteSession() );
                if ( magmaConduit ) {
                    actions.push( this.echoConduit.deleteSession() );
                } else {
                    localStorage.removeItem("conduit.session");
                }
            }
            AlErrorHandler.log( `SessionMgr: syncronizing session state [${thisToken ? thisToken.substring( thisToken.length - 8 ) : 'none'}/${thisExpiry}]` );
            const results = await Promise.allSettled( actions );
        } catch( e ) {
            AlErrorHandler.report( e, `Failed to synchronize session state to conduit` );
        } finally {
            this.lastSyncedToken = thisToken;
            this.lastSyncedExpiry = thisExpiry;
        }
    }
}
